class Scroll {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    	//ページ内スクロール
    var headerHeight = $('.l-header').outerHeight();     //fixedのヘッダーの高さを取得
    console.log(headerHeight);
    var urlHash = location.hash;    //URLハッシュを取得
    var animeSpeed = 400;   //スクロールのアニメーションスピード
    if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
        var target = $(urlHash);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);
      }, 100);
    }
    $('a[href*="#"]').on({
      'click': function(){
        var href= $(this).attr("href");
        if(href.slice(0,1) != "#") {
          var href= href.split("#")[1];
          var href= "#" + href;
        }
        var target = $(href);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);   
      }
    });
  }
}



