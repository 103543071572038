class PageTop {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    $(function(){
      var pagetop = $('#page-top');
      pagetop.hide();
      $(window).scroll(function () {
         if ($(this).scrollTop() > 100) {
              pagetop.fadeIn();
         } else {
              pagetop.fadeOut();
         }
      });
      pagetop.click(function () {
         $('body, html').animate({ scrollTop: 0 }, 500);
         return false;
      });
    });
  }
}
