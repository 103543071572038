// globals
class Accordion {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    $(function () {
      // タイトルをクリックすると
      $(".js-accordion-title").on("click", function () {
        // クリックした次の要素を開閉
        $(this).next().slideToggle(300);
        // タイトルにopenクラスを付け外しして矢印の向きを変更
        $(this).toggleClass("open", 300);
      });
    });
  }
}

var CFL = {
  UA: {
    raw: function raw() {
      return navigator.userAgent;
    },
    is: function is(_is) {
      var ua = CFL.UA.raw();
      if (_is === 'issp') {
        return CFL.UA.isSp();
      }
    },
    isSp: function isSp() {
      var ua = CFL.UA.raw();
      return ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0;
    },
    isTablet: function() {
      var ua = CFL.UA.raw();
      if(ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
      } else if (ua.indexOf('iPad') > -1
         || (ua.indexOf('Macintosh') > -1 && CFL.UA.Device.isTouch() )
         || ua.indexOf('Android') > 0) {
        return true;
      }

      return false;
    },
    isEdge: function() {
      return this.raw().indexOf('Edge') != -1;
    },
    isIE: function() {
      var ua = CFL.UA.raw();
      return this.raw().indexOf('Trident') != -1 ;
    }
  },
  Device: {
    isTouch: function() {
      return 'ontouchend' in document;
    }
  }
};

var isMobile = function () {
  return (window.matchMedia('(max-width: '+ APP.bp.mobile +'px)').matches)
}

var isPortrait = function() {
  let defaultOrientation = null;
  if('orientation' in window) {
    var o1 = (window.innerWidth < window.innerHeight);
    var o2 = (window.orientation % 180 == 0);
    defaultOrientation = (o1 && o2) || !(o1 || o2);
  }
  if('orientation' in window) {
    // defaultOrientationがtrueの場合、window.orientationが0か180の時は縦
    // defaultOrientationがfalseの場合、window.orientationが-90か90の時は横
    var o = (window.orientation % 180 == 0);
    if((o && defaultOrientation) || !(o || defaultOrientation)) {
      return true;
    } else {
      return false;
    }
  }
}
class Menu {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    $(function() {
      $('#toggle').on('click', function() {
        $('body').toggleClass('is-open');
      });
      $("#nav-content a").on("click", function(){
        $('body').removeClass("is-open");
    });
    });
  }
}




class PageTop {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    $(function(){
      var pagetop = $('#page-top');
      pagetop.hide();
      $(window).scroll(function () {
         if ($(this).scrollTop() > 100) {
              pagetop.fadeIn();
         } else {
              pagetop.fadeOut();
         }
      });
      pagetop.click(function () {
         $('body, html').animate({ scrollTop: 0 }, 500);
         return false;
      });
    });
  }
}

class Scroll {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    	//ページ内スクロール
    var headerHeight = $('.l-header').outerHeight();     //fixedのヘッダーの高さを取得
    console.log(headerHeight);
    var urlHash = location.hash;    //URLハッシュを取得
    var animeSpeed = 400;   //スクロールのアニメーションスピード
    if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
        var target = $(urlHash);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);
      }, 100);
    }
    $('a[href*="#"]').on({
      'click': function(){
        var href= $(this).attr("href");
        if(href.slice(0,1) != "#") {
          var href= href.split("#")[1];
          var href= "#" + href;
        }
        var target = $(href);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, animeSpeed);   
      }
    });
  }
}




class Tab {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    $(function(){
      $('.tab-list-item').on('click', function(){
        let index = $('.tab-list-item').index(this);
    
        $('.tab-list-item').removeClass('is-btn-active');
        $(this).eq(index).addClass('is-btn-active');
        $('.tab-contents').removeClass('is-contents-active');
        $('.tab-contents').eq(index).addClass('is-contents-active');
      });
    }); 
  }
}



(() => {

})();

//DOMContentLoaded
$(() => {
  let menu = new Menu("#toggle");
  let scroll = new Scroll(".l-header-nav");
  let tab = new Tab(".tab");
  let accordion = new Accordion(".accordion");
  let pagetop = new PageTop("#page-top");
});

//images resources loaded
$(window).on('load', () => {

  $(window).trigger('loading');
});

//after loading animation
$(window).on('loading', () => {

});

// スマホ・タブレットの向き判定
// $(window).on('orientationchange', ()=>{
//   if(isPortrait()){
//     console.log('isPortrait');
//   } else {
//     console.log('isLandscape');
//   }
// });