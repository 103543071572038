class Accordion {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    $(function () {
      // タイトルをクリックすると
      $(".js-accordion-title").on("click", function () {
        // クリックした次の要素を開閉
        $(this).next().slideToggle(300);
        // タイトルにopenクラスを付け外しして矢印の向きを変更
        $(this).toggleClass("open", 300);
      });
    });
  }
}
