class Menu {
  constructor(el, opt){
    this.el = el;
    if($(this.el).length > 0){
      this.event();
    }
  }
  event() {
    $(function() {
      $('#toggle').on('click', function() {
        $('body').toggleClass('is-open');
      });
      $("#nav-content a").on("click", function(){
        $('body').removeClass("is-open");
    });
    });
  }
}



